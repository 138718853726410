import React, { useState, useEffect } from 'react';

import ScrollSnap from './scroll-snap';
import Pager from './pager';
import SEO from '../components/seo';
import '../components/layout/layout.scss';
import ComingSoon from '../components/coming-soon';
import Layout from '../components/layout';
import DesktopPage from './desktop';

const BrowserDependantExp = (props) => {
  // const isBrowser = typeof window !== 'undefined';
  // const isSafari = isBrowser && navigator.userAgent.indexOf('iPhone OS 15') !== -1;
  //
  // const [ isClient, setClient ] = useState(false);
  // useEffect(() => {
  //   setClient(true);
  // }, []);
  // if (!isClient) {
  //   return <SEO />;
  // }

  return (
    <div>
      <div className='desktop'>
        <DesktopPage />
      </div>
      <div className='mobile'>
        <>
          <ScrollSnap {...props} />
          <Pager {...props} />
        </>
      </div>
    </div>
  );

  // if (isSafari) {
  //   return <ScrollSnap {...props} />;
  // } else {
  //   console.log('running pager');
  //   return <Pager {...props} />;
  // }
};


const pageStyles = {
  color: '#232129',
  padding: 20,
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
  textAlign: 'center'
};

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      Welcome to MedTrail
    </main>
  );
};

export default BrowserDependantExp;

export const Head = () => <title>MedTrail</title>;
