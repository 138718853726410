import React, { useEffect, useRef, useState } from 'react';
import { motion, useScroll } from 'framer-motion';
import HomePage from '../home';
import SAPage from '../smart-assistant';
import SRPage from '../smart-rep';
import MyHealthBookPage from '../home-myhealthbook';
import Pager from '../pager';
import Header from '../../components/header';
import { pagesHash } from '../../utils/constants';
import './desktop.scss';

const pageStyles = {
  color: '#232129',
  padding: 20,
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
  textAlign: 'center'
};

const DesktopPage = () => {
  return (
    <div className='mt-desktop-main container'>
      <div className='ss-fixed-header'>
        <div className='container'>
          <Header siteTitle={'MedTrail'} />
        </div>
      </div>
      <div className='mt-desktop-main-content'>
        <div className='main-container-desktop'>
          <div className='site-banner'>
            Smart products for doctors and healthcare service providers to
            collaborate and provide single-window experience to patients.
          </div>

          <div className='mt-all-products'>
            <SAPage isDesktop={true} />
            <SRPage isDesktop={true} />
            <MyHealthBookPage isDesktop={true} />
          </div>


        </div>

      </div>

    </div>

  );
};

export default DesktopPage;

export const Head = () => <title>MedTrail</title>;
